
import ManagementMixin from '@/mixins/ManagementMixin';
import EmployeeTab from '@/components/Management/User/EmployeeTab.vue';
import { mapState } from 'vuex';

export default {
  components: { EmployeeTab },
  mixins: [ManagementMixin],
  data() {
    return {};
  },
  created() {},
  methods: {
    openDrawer() {
      this.drawerShown = true;
    },
    closeDrawer() {
      this.drawerShown = false;
    }
  }
};
